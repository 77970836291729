import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { navigate } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  backgroundOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    marginTop: '45px',
    //background: 'blue',
    background: 'rgba(255, 255, 255, 0.7)',
    zIndex: 9,
  },
  backgroundOverlayMobile: {
    width: '100%',
    height: '100%',
    marginTop: '34px',
    position: 'absolute',
    //background: 'blue',
    background: 'rgba(255, 255, 255, 0.7)',
    zIndex: 9,
  },
}));

const ErrorPage = (): JSX.Element => {
  const { changeLanguage } = useI18next();
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMobile = useMediaQuery('(max-width:400px)');
  return (
    <div
      className={
        isMobile ? classes.backgroundOverlayMobile : classes.backgroundOverlay
      }
    >
      <Box
        style={{
          paddingTop: '66px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          fontFamily="Roboto"
          fontSize={isMobile ? 30 : 34}
          align={'left'}
          sx={{
            textTransform: 'capitalize',
          }}
          fontWeight={400}
          color="#0F1F38"
          component={'h4'}
          lineHeight={'36px'}
        >
          抱歉!
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={isMobile ? 30 : 34}
          align={'left'}
          sx={{
            textTransform: 'capitalize',
          }}
          fontWeight={400}
          color="#0F1F38"
          component={'h4'}
          lineHeight={'36px'}
        >
          此網誌只提供英文版本
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '275px',
          }}
        >
          <Box
            component={Button}
            variant="contained"
            size="large"
            fullWidth={isSm ? false : true}
            height={56}
            sx={{
              '&.MuiButton-contained': {
                backgroundColor: '#B79B75',
                color: '#0D204A',
              },
              marginTop: '24px',
            }}
            onClick={() => {
              changeLanguage('en');
            }}
          >
            閱讀英文版本
          </Box>

          <Box
            component={Button}
            variant="contained"
            size="large"
            fullWidth={isSm ? false : true}
            height={56}
            sx={{
              '&.MuiButton-contained': {
                backgroundColor: '#F7F4E9',
                color: '#0D204A',
              },
              marginTop: '24px',
            }}
            onClick={() => navigate(`/blog`)}
          >
            返回主頁
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ErrorPage;
