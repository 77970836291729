import React from 'react';
import Helmet from 'react-helmet';

const SEO = ({ title, description, keywords, image, lang }): JSX.Element => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={title} />
      <meta name="keywords" content={keywords} />
      <meta name="image" content={image} />
      <meta name="locale" content={lang === 'en' ? 'en_US' : 'zh_HK'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:keywords" content={keywords} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content={lang === 'en' ? 'en_US' : 'zh_HK'} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:keywords" content={keywords} />
      <meta property="twitter:image" content={image} />
      <meta
        property="twitter:locale"
        content={lang === 'en' ? 'en_US' : 'zh_HK'}
      />
    </Helmet>
  );
};

export default SEO;
