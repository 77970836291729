import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { navigate } from 'gatsby';
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import SocialMediaIcons from './SocialMediaIcons';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  contentImage: { marginTop: '24px' },
  section: {
    marginBottom: '33px',
    marginTop: '24px',
    width: '100%',
    overflow: 'hidden',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',

    letterSpacing: '0.5px',

    color: '#000000',
  },
  imageDesign: { maxHeigth: '316px', maxWidth: '725px', borderRadius: '10px ' },
  // blogImg: {
  //   marginTop: '50px',
  // },
  // mediaWrap: { marginTop: '50px' },

  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
}));

const Content = ({ data }): JSX.Element => {
  const i18Context = React.useContext(I18nextContext);
  const lang = i18Context.language;
  const classes = useStyles();
  const [reloading, setReloading] = useState(0);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:400px)');

  useEffect(() => {
    let blogImage = document.querySelector('#blogContent');
    if (blogImage) {
      let blogImages = document
        .getElementById('blogContent')
        .getElementsByTagName('img');
      for (let i = 0; i < blogImages.length; i++) {
        // let td = (blogImages[i].style.marginTop = '24px');
        // blogImages[i].style.height = '316px';
        // blogImages[i].style.width = '725px';
        // blogImages[i].style.borderRadius = '10px';
        let imageSrc = blogImages[i].src;
        blogImages[
          i
        ].outerHTML = `<div class=${classes.contentImage}><Image src=${imageSrc} class=${classes.imageDesign}  /></div>`;
      }
      //paragraph margin top
      let paras = document
        .getElementById('blogContent')
        .getElementsByTagName('p');
      for (let i = 0; i < paras.length; i++) {
        if (i !== 0) {
          paras[i].style.marginTop = '16px';
          paras[i].style.textIndent = '0';
        }
      }
    }

    if (reloading < 2) {
      setReloading(reloading + 1);
    }
  }, [reloading, data, classes]);

  return (
    <>
      <Box>
        <Box
          onClick={() => {
            navigate(`/blog`);
          }}
          marginBottom={'24px'}
          display={'flex'}
          alignItems={'center'}
          style={{ cursor: 'pointer', width: '80px' }}
        >
          <ArrowBackIcon />
          <Typography
            fontFamily="Roboto"
            fontSize={20}
            align={'left'}
            sx={{
              marginLeft: '8px',
              textTransform: 'capitalize',
              lineHeight: '24px',
              letterSpacing: '0.15px',
            }}
            fontWeight={500}
            color="#0F1F38"
          >
            {t('back')}
          </Typography>
        </Box>
        <div>
          {lang === 'en' ? (
            <Box marginBottom={'8px'}>
              <Typography
                fontFamily="Roboto"
                fontSize={isMobile ? 30 : 34}
                align={'left'}
                sx={{
                  textTransform: 'capitalize',
                }}
                fontWeight={400}
                color="#0F1F38"
                component={'h4'}
                lineHeight={'36px'}
              >
                {data?.engTitle}
              </Typography>
            </Box>
          ) : data?.cBlogStatus === 'PUBLISHED' ? (
            <>
              <Box marginBottom={'8px'}>
                <Typography
                  fontFamily="Roboto"
                  fontSize={isMobile ? 30 : 34}
                  align={'left'}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  fontWeight={400}
                  color="#0F1F38"
                  component={'h4'}
                  lineHeight={'36px'}
                >
                  {data?.chnTitle}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box marginBottom={'8px'}>
                <Typography
                  fontFamily="Roboto"
                  fontSize={isMobile ? 30 : 34}
                  align={'left'}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  fontWeight={400}
                  color="#0F1F38"
                  component={'h4'}
                  lineHeight={'36px'}
                >
                  {data?.engTitle}
                </Typography>
              </Box>
            </>
          )}
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: isMobile ? 'flex-start' : 'center',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '10px',
          }}
        >
          <Box>
            <Box maxWidth={266} sx={{ display: 'flex', marginBottom: '8px' }}>
              {data?.engTags.map((item, index) => {
                return (
                  <div style={{ display: 'flex' }} key={index}>
                    <Typography
                      key={index}
                      align={'left'}
                      fontFamily="Roboto"
                      fontSize="12px"
                      sx={{
                        textTransform: 'uppercase',
                        fontStyle: 'normal',
                        lineHeight: ' 16px',
                      }}
                      fontWeight={500}
                      color={'#B79B75'}
                      letterSpacing="1.5px"
                      lineHeight={'16px'}
                    >
                      {index !== data?.engTags.length - 1
                        ? lang === 'en'
                          ? item.tagNameEng
                          : item.tagNameChn
                        : lang === 'en'
                        ? item.tagNameEng
                        : item.tagNameChn}
                    </Typography>
                    <span
                      style={{
                        backgroundColor: '#B79B75',
                        height: '2px',
                        width: '2px',
                        alignSelf: 'center',
                        margin: '0px 8px',
                        display:
                          index !== data?.engTags.length - 1
                            ? 'inline'
                            : 'none',
                      }}
                    ></span>
                  </div>
                );
              })}
            </Box>
            <Box>
              <Typography
                fontFamily="Roboto"
                align={'left'}
                sx={{
                  textTransform: 'capitalize',
                }}
                fontWeight={400}
                color={'#0F1F38'}
                fontSize="14px"
                letterSpacing="0.4px"
                lineHeight={'16px'}
              >
                {data?.createdDate}
              </Typography>
            </Box>
          </Box>
          <Box>
            <SocialMediaIcons />
          </Box>
        </Box>
        {reloading ? (
          <>
            <div className={classes.section} id="blogContent">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    lang === 'en'
                      ? data.engContent
                      : data?.cBlogStatus === 'PUBLISHED'
                      ? data.chnContent
                      : data.engContent,
                }}
              ></div>
            </div>
          </>
        ) : null}

        <Box>
          <SocialMediaIcons />
        </Box>
      </Box>
    </>
  );
};

export default Content;
