import React from 'react';
import Typography from '@mui/material/Typography';
import { TopFiveBlogs } from 'components/TopFiveBlogs';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const SidebarArticles = ({ highlightData }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography
        fontFamily="Roboto"
        fontSize={20}
        align={'left'}
        sx={{
          textTransform: 'capitalize',
          marginTop: { lg: '28px' },
        }}
        fontWeight={500}
        color={'#0F1F38'}
        letterSpacing={'0.15px'}
      >
        {t('topFiveBlogs')}
      </Typography>
      <TopFiveBlogs highlightData={highlightData} />
    </div>
  );
};

export default SidebarArticles;
