/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import BlogArticle from 'views/BlogArticle';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { graphql } from 'gatsby';

const SingleBlog = ({ data }): JSX.Element => {
  return (
    <>
      <WithLayout
        component={BlogArticle}
        layout={Main}
        singleBlogData={data.DJANGO.allPost.edges[0]?.node}
        ad={data.DJANGO.allAds}
      />
    </>
  );
};

export default SingleBlog;

export const pageQuery = graphql`
  query solutionByID($slug: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    DJANGO {
      allPost(engSlug: $slug, orderBy: ["from_position"]) {
        edges {
          node {
            createdDate
            cBlogStatus
            engBanner
            chnBanner
            engCaption
            chnCaption
            engCategories {
              catNameEng
              catNameChn
            }
            engTags {
              tagNameEng
              tagNameChn
              keywordsEng
              keywordsChn
            }
            engContent
            chnContent
            engThumbnail
            chnThumbnail
            engTitle
            chnTitle
            engKeywords
            chnKeywords
          }
        }
      }
    }

    DJANGO {
      allAds {
        id
        upload
        urlLink
      }
    }
  }
`;
