import React from 'react';
import Box from '@mui/material/Box';

export default function Ad({ ad }) {
  const image = (
    <img
      src={`${ad?.upload}`}
      srcSet={`${ad?.upload}`}
      loading="lazy"
      style={{
        width: '100%',
        height: '202px',
      }}
      alt="ad"
    />
  );
  return (
    <Box>
      {ad?.urlLink ? (
        <a href={ad?.urlLink} target="_blank" rel="noreferrer">
          {image}
        </a>
      ) : (
        image
      )}
    </Box>
  );
}
