import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Linkedin from 'assets/svgs/linkedin.svg';
import Variant from 'assets/svgs/variant.svg';
import InstagramIcon from '@material-ui/icons/Instagram';
import { CustomMessage } from 'components/CustomMessage';

const useStyles = makeStyles(theme => ({
  socialIcon: {
    borderRadius: 50,
    color: '#FFFFFF',
    width: '24px',
    height: '24px',
    backgroundColor: '#0F1F38',
    '&:hover': {
      backgroundColor: '#0F1F38',
    },
    padding: '0 !important',
  },
}));

const SocialMediaIcons = (): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('info');
  const [message, setMessage] = useState('');
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    try {
      setCurrentURL(window.location.href);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(window.location.href).then(
        function() {
          setMessage('Link copied to clipboard');
          setSeverity('info');
          handleOpen();
        },
        function(err) {
          setMessage('Could not copy the URL');
          setSeverity('error');
          handleOpen();
          console.log(err);
        },
      );
    } catch (error) {
      setMessage('Could not copy the URL');
      setSeverity('error');
      handleOpen();
      console.log(error);
    }
  };

  const shareFacebook = () => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=0,height=0,left=-1000,top=-1000`;
    try {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
          currentURL,
        )}&amp;src=sdkpreparse`,
        'test',
        params,
      );
    } catch (error) {
      setMessage('Error occured while sharing');
      setSeverity('error');
      handleOpen();
      console.log('error', error);
    }
  };
  const shareLinkedIn = async () => {
    let params = 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0';
    try {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${currentURL}`,
        'mywin',
        params,
      );
    } catch (error) {
      setMessage('Error occured while sharing');
      setSeverity('error');
      handleOpen();
      console.log('error', error);
    }
  };

  return (
    <div>
      <CustomMessage
        open={open}
        severity={severity}
        message={message}
        handleClose={handleClose}
      />
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {/* <IconButton className={classes.socialIcon}>
        <InstagramIcon style={{ fontSize: 15 }} />
      </IconButton> */}
        <div
          className="fb-share-button"
          data-href={currentURL}
          data-layout="button_count"
          data-size="small"
        >
          <div onClick={shareFacebook} className="fb-xfbml-parse-ignore">
            <IconButton className={classes.socialIcon}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.20548 2.30656H10.4737V0.0978191C10.2549 0.0677209 9.50241 0 8.62609 0C6.79763 0 5.54508 1.1501 5.54508 3.26392V5.2093H3.52734V7.67851H5.54508V13.8915H8.01892V7.67909H9.95504L10.2624 5.20988H8.01834V3.50875C8.01892 2.79508 8.21108 2.30656 9.20548 2.30656Z"
                  fill="white"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <IconButton className={classes.socialIcon} onClick={shareLinkedIn}>
          <Linkedin />
        </IconButton>
        <IconButton className={classes.socialIcon} onClick={handleCopy}>
          <Variant />
        </IconButton>
      </div>
    </div>
  );
};

export default SocialMediaIcons;
